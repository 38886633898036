<template>
    <main>
        <header class=" bg-gradient-to-br from-[#FFD200] to-[#CD7D00]">
            <div
                class="container xl:max-w-[1280px] mx-auto py-10 pt-5 xl:py-5 2xl:py-5 3xl:py-7 xl:px-10 sm:px-5 px-5 text-center flex justify-center min-h-[620px] lg:min-h-[600px] 2xl:min-h-[600px] 3xl:min-h-[650px]">
                <div class="flex flex-col items-center">
                    <!-- <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-white">Smart</span> Shipping Rate Finder
                    </h3> -->
                    <h2
                        class="calc_heading mt-3 md:mt-5 text-[1.1rem] sm:text-[1.6rem] md:text-[1.9rem] lg:text-[2rem] xl:text-[2rem] 2xl:text-[2.2rem] leading-tight font-bold tracking-wider text-black">
                        Optimize Your Shipping Spend with <br class="block sm:hidden xl:block">
                        <span class="text-white">Top
                            Carrier Rates</span>
                    </h2>
                </div>
            </div>
        </header>

        <section class="min-h-[770px] lg:min-h-[220px] xl:min-h-[460px] 2xl:min-h-[500px] 3xl:min-h-auto">
            <div class="container xl:max-w-[1280px] mx-auto py-10 xl:py-10 2xl:py-10 3xl:py-16 xl:px-10 sm:px-5 px-5">
                <div
                    class="p-3 xl:p-5 grid grid-cols-1 lg:grid-cols-2 gap-3 xl:gap-5 bg-white border border-linear rounded-3xl -translate-y-[560px] sm:-translate-y-[520px] md:-translate-y-[510px] lg:-translate-y-[510px] xl:-translate-y-[490px] 2xl:-translate-y-[480px] 3xl:-translate-y-[550px] min-h-[640px] xl:min-h-[790px]">
                    <div class="space-y-3 xl:space-y-4 h-full">
                        <h4
                            class="text-linear text-lg lg:text-xl xl:text-[22px] font-bold text-center rounded-t-xl shadow-[inset_0_4px_10px_0px_rgba(0,0,0,0.1)] py-4">
                            Get a quote without signing up</h4>
                        <div class="py-3 px-3 xl:px-5 xl:py-5 rounded-xl shadow-[inset_0_0_2px_0.35px_rgba(0,0,0,0.1)]">

                            <div class="px-0.5 grid grid-cols-1 sm:grid-cols-2 gap-2 xl:gap-3 mb-6 cursor-pointer">
                                <div :class="['py-2 xl:py-3 flex gap-1 xl:gap-2 justify-center items-center text-center shadow-[inset_0_4px_10px_0px_rgba(0,0,0,0.1)] rounded-t-xl',
                                    shipDomestic ? 'bg-gradient-to-br from-[#FFD200] to-[#CD7D00]' : 'bg-white']"
                                    @click="shipDomesticFunc('us')">
                                    <img class="w-7 h-7 lg:w-8 lg:h-8 xl:w-9 xl:h-9"
                                        src="../assets/images/calc/usa_flag.svg" alt="">
                                    <h6 class="font-semibold text-sm xl:text-base flex items-center gap-1"
                                        :class="shipDomestic ? 'text-white' : 'text-black'">
                                        Ship within <span class="hidden xl:block">the</span> U.S
                                    </h6>
                                </div>
                                <div :class="['py-2 xl:py-3 flex gap-1 xl:gap-2 justify-center items-center text-center shadow-[inset_0_4px_10px_0px_rgba(0,0,0,0.1)] rounded-t-xl',
                                    shipDomestic ? 'bg-white' : 'bg-gradient-to-br from-[#FFD200] to-[#CD7D00]']"
                                    @click="shipDomesticFunc('international')">
                                    <img class="w-7 h-7 lg:w-8 lg:h-8 xl:w-9 xl:h-9"
                                        src="../assets/images/calc/world_icon.png" alt="">
                                    <h6 class="font-semibold text-sm xl:text-base"
                                        :class="shipDomestic ? 'text-black' : 'text-white'">Ship International</h6>
                                </div>
                            </div>

                            <div
                                class="overflow-x-hidden min-h-[330px] sm:min-h-[380px] md:min-h-[400px] xl:min-h-[590px] overflow-y-auto">
                                <div
                                    class="py-0 xl:py-0 px-0.5 grid gap-2 lg:gap-4 grid-cols-1 md:grid-cols-2 items-start">
                                    <div>
                                        <label for="input-group-1"
                                            class="block mb-2 text-xs sm:text-sm font-medium text-gray-900">Ship
                                            From</label>
                                        <div class="relative w-full">
                                            <div
                                                class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                <i class="fa-solid fa-location-dot"></i>
                                            </div>
                                            <select id="input-group-1" v-model="form.ship_from_country_code"
                                                class="minimal bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] block w-full pl-10 p-2.5">
                                                <option value="US">United States</option>
                                            </select>
                                        </div>
                                        <span class="text-red-600 text-xs">{{ errors.ship_from_country_code }}</span>
                                    </div>
                                    <div>
                                        <label for="input-group-2"
                                            class="block mb-2 text-xs sm:text-sm font-medium text-gray-900">Zip
                                            Code</label>
                                        <div class="relative w-full">
                                            <div
                                                class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                <i class="fas fa-list"></i>
                                            </div>
                                            <input id="input-group-3" v-model="form.ship_from_postal_code"
                                                placeholder=""
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full pl-10 p-2.5" />
                                        </div>
                                        <span class="text-red-600 text-xs">{{ errors.ship_from_postal_code }}</span>
                                    </div>

                                    <div>
                                        <label for="input-group-2"
                                            class="block mb-2 text-xs sm:text-sm font-medium text-gray-900">Ship
                                            To</label>
                                        <div class="relative w-full">
                                            <div
                                                class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                <i class="fa-solid fa-location-dot"></i>
                                            </div>
                                            <select id="input-group-2" v-model="form.ship_to_country_code"
                                                class="minimal bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] block w-full pl-10 p-2.5">
                                                <option value="">Choose a country</option>
                                                <template v-for="country in countries" :key="country.id">
                                                    <option :value="country.iso">{{ country.nicename }}</option>
                                                </template>
                                            </select>
                                        </div>
                                        <span class="text-red-600 text-xs">{{ errors.ship_to_country_code }}</span>
                                    </div>
                                    <div class="col-span-1 grid grid-cols-2 gap-4 items-center">
                                        <div>
                                            <label for="input-group-2"
                                                class="block mb-2 text-xs sm:text-sm font-medium text-gray-900">Zip
                                                Code</label>
                                            <div class="relative w-full">
                                                <div
                                                    class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                    <i class="fas fa-list"></i>
                                                </div>
                                                <input id="input-group-3" v-model="form.ship_to_postal_code"
                                                    placeholder=""
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full pl-10 p-2.5" />
                                            </div>
                                            <span class="text-red-600 text-xs">{{ errors.ship_to_postal_code }}</span>
                                        </div>
                                        <div>
                                            <label for="input-group-2"
                                                class="block mb-2 text-xs sm:text-sm font-medium text-gray-900 ">City</label>
                                            <div class="relative w-full">
                                                <div
                                                    class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                    <i class="fa-solid fa-location-dot"></i>
                                                </div>
                                                <input id="input-group-3" v-model="form.ship_to_city" placeholder=""
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full pl-10 p-2.5" />
                                            </div>
                                            <span class="text-red-600 text-xs">{{ errors.ship_to_city }}</span>
                                        </div>
                                    </div>
                                    <div class="col-span-1 grid grid-cols-2 gap-4 items-center">
                                        <div>
                                            <label for="input-group-2"
                                                class="block mb-2 text-xs sm:text-sm font-medium text-gray-900">Is
                                                Residential?</label>
                                            <div class="relative w-full">
                                                <div
                                                    class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                    <i class="fa-solid fa-location-dot"></i>
                                                </div>
                                                <select id="input-group-2" v-model="form.is_residential"
                                                    class="minimal bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] block w-full pl-10 p-2.5">
                                                    <option :value="true">Yes</option>
                                                    <option :value="false">No</option>
                                                </select>
                                            </div>
                                            <span class="text-red-600 text-xs">{{ errors.is_residential }}</span>
                                        </div>
                                        <div>
                                            <label for="input-group-2"
                                                class="block mb-2 text-xs sm:text-sm font-medium text-gray-900">Value
                                                <small class="text-gray-400">USD</small>
                                            </label>
                                            <div class="relative w-full">
                                                <div
                                                    class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                    <i class="fas fa-dollar"></i>
                                                </div>
                                                <input id="input-group-3" v-model="form.insurance_amount" placeholder=""
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full pl-10 p-2.5" />
                                            </div>
                                            <span class="text-red-600 text-xs">{{ errors.insurance_amount }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="grid grid-cols-5 gap-2 xl:gap-4 mt-4">
                                        <label
                                            class="col-span-1 block text-xs sm:text-sm font-medium text-[#2E2E2E]">
                                            Pkgs
                                        </label>
                                        <label
                                            class="col-span-1 block text-xs sm:text-sm font-medium text-[#2E2E2E]">Weight
                                            <small class="text-gray-400 text-[61%] sm:text-[80%]">lbs</small>
                                        </label>
                                        <label
                                            class="col-span-1 block text-xs sm:text-sm font-medium text-[#2E2E2E] whitespace-nowrap">Dimensions
                                            <small class="inline text-[61%] sm:text-[80%]">(L x W x H)</small>
                                            <small class="text-gray-400 text-[61%] sm:text-[80%]"> inch</small>
                                        </label>
                                    </div>
                                    <div class="space-y-3.5 pr-2">
                                        <!-- Loop Start Place (Where Weight & Dimensions loop needs to be ....)  -->
                                        <template v-for="(dimension, index) in form.dimensions" :key="dimension.id">
                                            <div class="grid grid-cols-5 gap-2 py-2.5 rounded-md relative"
                                                :class="{ 'bg-[#ffe6b5] border-[#f9c554] border-2 px-2.5': form.dimensions.length !== (index + 1) }">

                                                <div class="col-span-1">
                                                    <div class="relative w-full">
                                                        <div
                                                            class="hide-on-mobile absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                            <i class="fa-solid fa-list"></i>
                                                        </div>
                                                        <input id="input-group-3" v-model="dimension.no_of_pkg"
                                                            placeholder=""
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full md:pl-10 p-2.5" />
                                                    </div>
                                                    <span class="text-red-600 text-xs">{{
                                                        errors[`dimensions.0.no_of_pkg`] }}</span>
                                                </div>

                                                <div class="col-span-1">
                                                    <div class="relative w-full">
                                                        <div
                                                            class="hide-on-mobile absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                            <i class="fa-solid fa-weight-scale"></i>
                                                        </div>
                                                        <input id="input-group-3" v-model="dimension.weight"
                                                            placeholder=""
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full md:pl-10 p-2.5" />
                                                    </div>
                                                    <span class="text-red-600 text-xs">{{
                                                        errors[`dimensions.0.weight`] }}</span>
                                                </div>

                                                <div class="col-span-1">
                                                    <div class="relative w-full">
                                                        <div
                                                            class="hide-on-mobile absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                            <i class="fa-solid fa-ruler"></i>
                                                        </div>
                                                        <input id="input-group-3" v-model="dimension.length"
                                                            placeholder=""
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full md:pl-10 p-2.5" />
                                                    </div>
                                                    <span class="text-red-600 text-xs">{{
                                                        errors[`dimensions.0.length`] }}</span>
                                                </div>

                                                <div class="col-span-1">
                                                    <div class="relative w-full">
                                                        <div
                                                            class="hide-on-mobile absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                            <i class="fa-solid fa-ruler-horizontal"></i>
                                                        </div>
                                                        <input id="input-group-3" v-model="dimension.width"
                                                            placeholder=""
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full md:pl-10 p-2.5" />
                                                    </div>
                                                    <span class="text-red-600 text-xs">{{ errors[`dimensions.0.width`]
                                                        }}</span>
                                                </div>

                                                <div class="col-span-1">
                                                    <div class="relative w-full">
                                                        <div
                                                            class="hide-on-mobile absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                            <i class="fa-solid fa-ruler-vertical"></i>
                                                        </div>
                                                        <input id="input-group-3" v-model="dimension.height"
                                                            placeholder=""
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:ring-[#CD7D00] focus:border-[#CD7D00] focus-within:outline-[#CD7D00] block w-full md:pl-10 p-2.5" />
                                                    </div>
                                                    <span class="text-red-600 text-xs">{{
                                                        errors[`dimensions.0.height`] }}</span>
                                                </div>
                                                <button v-if="form.dimensions.length !== (index + 1)"
                                                    @click="removeDimension(index)"
                                                    class="rounded-full h-6 w-6 text-sm bg-red-500 text-white absolute -top-3.5 -right-2"
                                                    type="button"><i class="fas fa-close"></i></button>
                                            </div>
                                        </template>

                                        <!-- Loop End Place -->

                                        <div class="mt-6 space-y-3">
                                            <button @click="addDimension"
                                                class="w-full bg-gray-50 border border-gray-100 shadow-[inset_0_0_2px_0.35px_rgba(0,0,0,0.1)] text-[#2E2E2E] py-3 rounded-md flex justify-center items-center text-sm xl:text-base cursor-pointer">
                                                Add Item +</button>
                                        </div>
                                    </div>

                                    <div
                                        class="bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-md p-3 md:p-3 mr-2 mt-8">
                                        <div
                                            class="px-1 sm:px-5 lg:px-10 py-4 rounded-md bg-white flex justify-center items-center gap-3">
                                            <img class="w-9" src="../assets/images/mini_box.svg" alt=""> <span
                                                class="text-linear font-medium text-xs sm:text-base"> Save up to 80% on
                                                Shipping!</span>
                                        </div>
                                    </div>

                                    <div
                                        class="flex justify-center items-center py-2 xl:py-4 pt-6 xl:pt-6 lg:h-[100px]">
                                        <button @click="showResults"
                                            class="border-[3px] border-linear bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-12 py-2.5 text-white rounded-md hover:shadow-md hover:border-0 hover:rounded-sm">{{
                                                loading ? 'Loading ...' : 'Get Rates' }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="space-y-3 xl:space-y-4 h-full">
                        <!-- <h4
                            class="text-linear text-lg lg:text-xl xl:text-[22px] font-bold text-center rounded-t-xl shadow-[inset_0_4px_10px_0px_rgba(0,0,0,0.1)] py-5 lg:pb-8">
                            Results</h4> -->

                        <div class="h-full overflow-x-hidden">
                            <ResultComponent v-if="show_results" :rates="rates" :form="form" :loading="loading">
                            </ResultComponent>

                            <template v-else>
                                <div
                                    class="h-full flex flex-col justify-center items-center py-3 px-5 xl:px-8 xl:py-8 rounded-xl shadow-[inset_0_0_2px_0.35px_rgba(0,0,0,0.1)]">
                                    <img class="h-36 w-52 3xl:h-44 3xl:w-64 rounded-full object-contain"
                                        src="../assets/logo.svg" alt="logo">
                                    <h2
                                        class="mt-1 md:mt-2 text-center text-[1.6rem] md:text-[1.6rem] lg:text-[1.8rem] xl:text-[2rem] 2xl:text-[2.2rem] leading-tight font-bold tracking-wider text-black">
                                        Effortless Shipping with <br class="hidden 2xl:block"> <span
                                            class="text-linear">Self Ship Label</span>
                                    </h2>
                                </div>
                            </template>



                        </div>

                    </div>
                </div>
            </div>
        </section>

        <!-- ============== SECTION =============  -->
        <!-- <section class="mt-[-400px] overflow-x-hidden">
            <div
                class="2xl:container mx-auto py-10 pb-20 xl:py-10 xl:pb-20 2xl:py-10 2xl:pb-20 3xl:py-16 3xl:pb-24 xl:px-10 sm:px-5 px-5 grid grid-cols-1 lg:grid-cols-2 justify-items-end lg:justify-items-center md:justify-items-stretch gap-10 lg:gap-0 xl:gap-5 2xl:gap-0">

                <div data-aos="fade-right"
                    class="order-2 lg:order-none flex flex-col gap-14 sm:gap-20 items-end lg:items-center justify-center sm:px-10 xl:px-0 w-full md:w-auto">
        
                    <div
                        class="relative section2_card grid grid-cols-[80px,1fr] sm:grid-cols-[100px,1fr,130px] xl:grid-cols-[100px,1fr,140px] items-center gap-2 xl:gap-4 rounded-md p-2 xl:p-4 bg-white border">
                        <img class="w-[80px] h-[72px] lg:w-[95px] lg:h-[86px] object-cover rounded-md bg-purple-300"
                            src='../assets/images/calc/fedex.svg' alt="fedex">
                        <div class="text-[#676767]">
                            <h6 class="font-bold xl:text-lg text-[#2E2E2E]">FedEx Home Delivery ®</h6>
                            <p class="text-xs xl:text-sm mb-4">More about fedex Shipping</p>
                            <p class="text-xs text-[#2E7E31]">Delivered by August 14, 2024 | 12:00 PM</p>
                        </div>
                        <div
                            class="flex flex-col justify-between items-end text-[#676767] h-full gap-2 sm:gap-0 col-span-2 sm:col-span-1">
                            <div class="flex justify-between items-center sm:block sm:w-auto w-full">
                                <div
                                    class="bg-white rounded-md sm:absolute top-2 right-20 xl:right-28 py-1 px-5 text-xs xl:text-sm">
                                    <span class="text-linear">40% Off</span>
                                </div>
                                <p class="font-normal text-xl xl:text-2xl text-[#2E2E2E]"><span>$27.44</span></p>
                            </div>
                            <button
                                class="w-full sm:w-auto px-6 xl:px-8 py-1 xl:py-2 border-2 border-linear text-[#EEB500] rounded-md">Ship
                                now</button>
                        </div>
                    </div>
              
                    <div
                        class="section2_card sm:after:left-[5.5%] sm:before:left-[8%] 3xl:after:left-[12.5%] 3xl:before:left-[15%] self-start grid grid-cols-[80px,1fr] sm:grid-cols-[100px,1fr,130px] xl:grid-cols-[100px,1fr,140px] items-center gap-2 xl:gap-4 rounded-md p-2 xl:p-4 bg-white border">
                        <img class="w-[80px] h-[72px] lg:w-[95px] lg:h-[86px] object-cover rounded-md bg-purple-300"
                            src='../assets/images/calc/fedex.svg' alt="fedex">
                        <div class="text-[#676767]">
                            <h6 class="font-bold xl:text-lg text-[#2E2E2E]">FedEx Home Delivery ®</h6>
                            <p class="text-xs xl:text-sm mb-4 text-[#676767] ">More about fedex Shipping</p>
                            <p class="text-xs text-[#2E7E31]">Delivered by August 14, 2024 | 12:00 PM</p>
                        </div>
                        <div
                            class="flex flex-col justify-between items-end text-[#676767] h-full gap-2 sm:gap-0 col-span-2 sm:col-span-1">
                            <div class="flex justify-between items-center sm:block sm:w-auto w-full">
                                <div
                                    class="bg-white rounded-md sm:absolute top-2 right-20 xl:right-28 py-1 px-5 text-xs xl:text-sm">
                                    <span class="text-linear">40% Off</span>
                                </div>
                                <p class="font-normal text-xl xl:text-2xl text-[#2E2E2E]"><span>$27.44</span></p>
                            </div>
                            <button
                                class="w-full sm:w-auto px-6 xl:px-8 py-1 xl:py-2 border-2 border-linear text-[#EEB500] rounded-md">Ship
                                now</button>
                        </div>
                    </div>
          
                    <div
                        class="section2_card grid grid-cols-[80px,1fr] sm:grid-cols-[100px,1fr,130px] xl:grid-cols-[100px,1fr,140px] items-center gap-2 xl:gap-4 rounded-md p-2 xl:p-4 bg-white border">
                        <img class="w-[80px] h-[72px] lg:w-[95px] lg:h-[86px] object-cover rounded-md bg-purple-300"
                            src='../assets/images/calc/fedex.svg' alt="fedex">
                        <div class="text-[#676767]">
                            <h6 class="font-bold xl:text-lg text-[#2E2E2E]">FedEx Home Delivery ®</h6>
                            <p class="text-xs xl:text-sm mb-4 text-[#676767]">More about fedex Shipping</p>
                            <p class="text-xs text-[#2E7E31]">Delivered by August 14, 2024 | 12:00 PM</p>
                        </div>
                        <div
                            class="flex flex-col justify-between items-end text-[#676767] h-full gap-2 sm:gap-0 col-span-2 sm:col-span-1">
                            <div class="flex justify-between items-center sm:block sm:w-auto w-full">
                                <div
                                    class="bg-white rounded-md sm:absolute top-2 right-20 xl:right-28 py-1 px-5 text-xs xl:text-sm">
                                    <span class="text-linear">40% Off</span>
                                </div>
                                <p class="font-normal text-xl xl:text-2xl text-[#2E2E2E]"><span>$27.44</span></p>
                            </div>
                            <button
                                class="w-full sm:w-auto px-6 xl:px-8 py-1 xl:py-2 border-2 border-linear text-[#EEB500] rounded-md">Ship
                                now</button>
                        </div>
                    </div>

                </div>

                <div data-aos="fade-left" class="flex flex-col justify-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">Experience</span> Effortless Shipping
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-left text-[1.8rem] md:text-[2.1rem] lg:text-[2.6rem] xl:text-[2.85rem] 2xl:text-[3rem] leading-tight font-bold tracking-wider text-black">
                        All-in-One Shipping Made <br class="hidden 2xl:block"> <span class="text-linear">Effortless</span>
                    </h2>
                    <p
                        class="mt-2 lg:mt-3 xl:mt-6 2xl:text-lg lg:leading-8 text-[#676767] xl:max-w-2xl text-justify sm:text-left">
                        Shipping packages worldwide is now a breeze with Self Ship Label. Take charge of your shipments
                        with
                        our user-friendly platform, designed to simplify the process. Whether you're sending gifts or
                        managing global orders, Self Ship Label ensures a smooth, reliable experience from start to
                        finish.
                        Trust us to handle your shipping needs with ease and confidence.</p>
                    <div class="mt-4 lg:mt-6 flex items-center gap-2 font-medium lg:h-[60px]">
                       <router-link to="/about"><button
                                class="bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-10 py-2 xl:px-12 xl:py-2.5 text-white rounded-md hover:shadow-md hover:rounded-sm transition-all">About</button></router-link>
                        <router-link to="/contact-us"><button
                                class="border-[3px] border-linear px-6 py-1.5 xl:px-10 xl:py-2 bg-white text-[#EEB500] rounded-md hover:bg-gradient-to-r hover:from-[#FFD200] hover:to-[#CD7D00] hover:text-white hover:py-2.5 hover:px-11 hover:shadow-md hover:rounded-sm hover:border-0 transition-all">Contact
                                us</button></router-link>
                    </div>
                </div>

            </div>
        </section> -->
        <!-- ============== SECTION =============  -->
        <section class="mt-[-600px] sm:mt-[-500px] 3xl:mt-[-600px] overflow-x-hidden">
            <div
                class="container xl:max-w-[1280px] mx-auto py-10 xl:py-10 2xl:py-10 3xl:py-16 3xl:pb-0 2xl:pb-0 xl:px-10 sm:px-5 px-5 grid grid-cols-1 lg:grid-cols-2 justify-items-center lg:justify-items-stretch gap-10 lg:gap-0">


                <div data-aos="fade-right" class="flex flex-col justify-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">Experience</span> Effortless Shipping
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-left text-[1.8rem] md:text-[2.1rem] lg:text-[2.6rem] xl:text-[2.85rem] 2xl:text-[3rem] leading-tight font-bold tracking-wider text-black">
                        All-in-One Shipping Made <span class="text-linear">Effortless</span>
                    </h2>
                    <p
                        class="mt-2 lg:mt-3 xl:mt-6 2xl:text-lg lg:leading-8 text-[#676767] xl:max-w-2xl text-justify sm:text-left">
                        Shipping packages worldwide is now a breeze with Self Ship Label. Take charge of your shipments
                        with
                        our user-friendly platform, designed to simplify the process. Whether you're sending gifts or
                        managing global orders, Self Ship Label ensures a smooth, reliable experience from start to
                        finish.
                        Trust us to handle your shipping needs with ease and confidence.</p>
                    <div class="mt-4 lg:mt-6 flex items-center gap-2 font-medium lg:h-[50px]">
                        <router-link to="/about"><button
                                class="bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-10 py-2 xl:px-12 xl:py-2.5 text-white rounded-md hover:shadow-md hover:rounded-sm transition-all">About</button></router-link>
                        <router-link to="/contact-us"><button
                                class="border-[3px] border-linear px-6 py-1.5 xl:px-10 xl:py-2 bg-white text-[#EEB500] rounded-md hover:bg-gradient-to-r hover:from-[#FFD200] hover:to-[#CD7D00] hover:text-white hover:py-2.5 hover:px-11 hover:shadow-md hover:rounded-sm hover:border-0 transition-all">Contact
                                us</button></router-link>
                    </div>
                </div>

                <div data-aos="fade-left"
                    class="flex flex-col gap-3 sm:gap-5 items-start lg:items-start justify-start w-full">
                    <!-- Card 1 -->
                    <div
                        class="w-full sm:w-[65%] flex justify-center items-center gap-4 rounded-full bg-gradient-to-br from-[#FFD200] to-[#CD7D00] p-2 border shadow-md">
                        <div class="w-full h-[65px] sm:h-[80px] bg-white rounded-full flex justify-center items-center">
                            <img class="w-[132px] sm:w-[162px] object-contain h-[35px] sm:h-[45px] rounded-full bg-white"
                                src='../assets/images/calc/fedex_logo.png' alt="">
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div
                        class="w-full sm:w-[65%] self-end flex justify-center items-center gap-4 rounded-full bg-gradient-to-br from-[#FFD200] to-[#CD7D00] p-2 border shadow-md">
                        <div class="w-full h-[65px] sm:h-[80px] bg-white rounded-full flex justify-center items-center">
                            <img class="w-[150px] sm:w-[200px] object-contain h-[50px] sm:h-[60px] rounded-full bg-white"
                                src='../assets/images/calc/dhl_logo.png' alt="">
                        </div>
                    </div>
                    <div
                        class="w-full sm:w-[65%] flex justify-center items-center gap-4 rounded-full bg-gradient-to-br from-[#FFD200] to-[#CD7D00] p-2 border shadow-md">
                        <div class="w-full h-[65px] sm:h-[80px] bg-white rounded-full flex justify-center items-center">
                            <img class="w-[150px] sm:w-[182px] object-contain h-[55px] sm:h-[75px] rounded-full bg-white"
                                src='../assets/images/calc/ups_big.svg' alt="">
                        </div>
                    </div>

                    <div
                        class="w-full sm:w-[65%] self-end flex justify-center items-center gap-4 rounded-full bg-gradient-to-br from-[#FFD200] to-[#CD7D00] p-2 border shadow-md">
                        <div class="w-full h-[65px] sm:h-[80px] bg-white rounded-full flex justify-center items-center">
                            <img class="w-[150px] sm:w-[182px] object-contain h-[35px] sm:h-[45px] rounded-full bg-white"
                                src='../assets/images/calc/usa_postal_logo.png' alt="">
                        </div>
                    </div>
                    <div
                        class="w-full sm:w-[65%] flex justify-center items-center gap-4 rounded-full bg-gradient-to-br from-[#FFD200] to-[#CD7D00] p-2 border shadow-md">
                        <div class="w-full h-[65px] sm:h-[80px] bg-white rounded-full flex justify-center items-center">
                            <img class="w-[142px] sm:w-[182px] object-contain h-[50px] sm:h-[65px] rounded-full bg-white"
                                src='../assets/images/calc/xps_logo.png' alt="">
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section class="overflow-hidden">
            <div class="container xl:max-w-[1280px] mx-auto py-10 xl:py-5 2xl:py-10 xl:px-10 sm:px-5 px-5">

                <div class="flex flex-col justify-center text-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">Common</span> Concerns, Clear Answers
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-[1.8rem] md:text-[2.1rem] lg:text-[2.6rem] xl:text-[2.85rem] 2xl:text-[3rem] leading-tight font-bold tracking-wider text-[#2E2E2E]">
                        Frequently Asked <span class="text-linear">Questions</span></h2>
                </div>
                <div class="pt-5 xl:pt-10 pb-10 xl:px-28">
                    <div data-aos="fade-up"
                        class="bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-2xl p-3 md:p-4">
                        <div class="px-5 sm:px-5 lg:px-10 py-4 rounded-2xl bg-white">
                            <div v-for="(item, index) in itemData" :key="index"
                                class="item py-0 mt-4 md:mt-6 overflow-hidden">
                                <a href="#"
                                    class="flex items-center justify-start gap-2 sm:gap-5 text-[1.05rem] lg:text-[1.1rem] pb-2 sm:pb-3 transition-all"
                                    :class="{ 'border-b-linear': index !== itemData.length - 1 }"
                                    @click.prevent="toggleItem(index)">
                                    <i
                                        :class="{ 'fa-solid fa-minus text-linear transition-all': item.itemCheck, 'fa-solid fa-plus text-linear transition-all': !item.itemCheck }"></i>
                                    <h4 class="text-[#2E2E2E] font-semibold">{{ item.itemHeading }}</h4>
                                </a>
                                <div class="overflow-hidden transition-all">
                                    <p class="mt-3 pl-2 sm:pl-9 transition-all md:text-base text-justify sm:text-left"
                                        :class="{ 'text-[#676767] transition-all': item.itemCheck, 'h-0 transition-all': !item.itemCheck }">
                                        {{ item.itemPara }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </main>
</template>
<style>
@media screen and (max-width:375px) {
    .calc_heading {
        font-size: 17px !important;
        padding-top: 10px;
    }
}
</style>

<script>
import ResultComponent from './ResultComponent.vue';
import { onMounted } from 'vue';

import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
    data() {
        return {
            itemData: [
                {
                    itemCheck: false,
                    itemHeading: "What is Self Ship Label?",
                    itemPara: "Our cutting-edge technology, Self Ship Label, gives users the freedom to independently handle their international shipments. For tasks like getting quotations, selecting couriers, making online payments, attaching labels, and more, it provides an easy-to-use interface.",
                },
                {
                    itemCheck: false,
                    itemHeading: "How can I use Self Ship Label to get started?",
                    itemPara: "It's simple to get going! All you have to do is create an account with Self Ship Label to access the Self Ship Label portal. With the help of our user-friendly tools, you can start creating and managing your shipments as soon as you log in. Happy Shipping!",
                },
                {
                    itemCheck: false,
                    itemHeading: "Is Self Ship Label available for international shipping ?",
                    itemPara: "Yes! The purpose of Self Ship Label is to facilitate both local and international shipping. Our platform offers everything you need for a smooth shipping experience, regardless of whether you're exporting locally or internationally.",
                },
                {
                    itemCheck: false,
                    itemHeading: "How does the shipping calculator work ?",
                    itemPara: "The destination, package weight, and dimensions are just a few of the details our shipping calculator uses to offer you instant quotations. It lets you select the shipping option that would save you the most money by comparing prices from different couriers.",
                },
                {
                    itemCheck: false,
                    itemHeading: "Do you have any extra or secret fees?",
                    itemPara: "Transparency is something we value. Our shipping calculator generates prices that include all applicable taxes and fees. There are no additional fees; you only pay what is shown.",
                },
                {
                    itemCheck: false,
                    itemHeading: "Which payment methods are available on Self Ship Label ?",
                    itemPara: "We accept multiple methods of payment, such as secure online payment platforms and popular credit cards. You can be sure that the highest level of security is being used for your transactions.",
                },
                {
                    itemCheck: false,
                    itemHeading: "Can I track my shipments with Self Ship Label ?",
                    itemPara: "Yes, you can! Every shipment processed through Self Ship Label comes with door-to-door tracking. Monitor the status of your package in real-time and stay informed throughout its journey.",
                },
                {
                    itemCheck: false,
                    itemHeading: "What if I need assistance during the shipping process ?",
                    itemPara: "Our customer support team is here to help! If you have any questions, concerns, or require assistance at any stage of the shipping process, feel free to reach out to our dedicated support team via the contact information provided on our website.",
                },
                {
                    itemCheck: false,
                    itemHeading: "How can I get a refund or make changes to my shipment ?",
                    itemPara: "For information on refunds or making changes to your shipment, please refer to our Terms and Conditions. If you need further assistance, contact our customer support, and we'll guide you through the process.",
                },
            ],
            loading: false,
            dimension_modal: false,
            show_results: false,
            countries: [],
            rates: [],
            length: "L",
            width: "W",
            height: "H",
            form: {
                is_residential: true,
                ship_from_country_code: "US",
                ship_from_postal_code: "",
                insurance_amount: "",
                ship_to_country_code: "",
                ship_to_city: "",
                ship_to_postal_code: "",
                address_type: true,
                dimensions: [
                    {
                        no_of_pkg: 1,
                        weight: "",
                        length: "",
                        width: "",
                        height: "",
                    }
                ],
                rate: {}
            },
            pkg: {},
            errors: {},
            shipDomestic: false,
        };
    },
    components: { ResultComponent },
    mounted() {
        this.fetchCountries();
        window.scrollTo(0, 0);

        // if (localStorage.getItem('rates')) {
        //     this.rates = JSON.parse(localStorage.getItem('rates'));
        //     this.show_results = true;
        // }
    },
    methods: {
        toggleItem(index) {
            this.itemData[index].itemCheck = !this.itemData[index].itemCheck;
        },
        openDimensionModal() {
            this.dimension_modal = true;
        },
        closeDimensionModal() {
            this.dimension_modal = false;
        },
        showResults() {
            this.show_results = true;
            this.fetchRates();
        },
        fetchCountries() {
            const url = "data"
            this.$axios
                .post(url, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.countries = response.data.data.countries;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchRates() {
            // localStorage.setItem("ship_from_postal_code", this.form.ship_from_postal_code);
            // localStorage.setItem("ship_to_country_code", this.form.ship_to_country_code);
            // localStorage.setItem("insurance_amount", this.form.insurance_amount);
            // localStorage.setItem("ship_to_city", this.form.ship_to_city);
            // localStorage.setItem("ship_to_postal_code", this.form.ship_to_postal_code);
            // localStorage.setItem("weight", this.form.dimensions[0].weight);
            // localStorage.setItem("length", this.form.dimensions[0].length);
            // localStorage.setItem("width", this.form.dimensions[0].width);
            // localStorage.setItem("height", this.form.dimensions[0].height);

            this.loading = true;
            this.dimension_modal = false;
            this.errors = {};
            const url = "rates"
            this.$axios
                .post(url, this.form, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.rates = response.data.data;
                    this.loading = false;
                    // localStorage.setItem('rates', JSON.stringify(this.rates));
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.dimension_modal = true;
                    this.loading = false;
                });
        },
        setRate(rate) {
            this.form1.rate = rate;
            this.loading = true;
            this.errors = {};
            this.$axios
                .post("package/set-rate", this.form1, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    this.errors = error.response;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchPackage() {
            this.loading = true;
            const url = "package/get-package"
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.pkg = response.data.data.package;
                    this.loading = false;
                    this.form.ship_from_country_code = this.pkg.ship_from.country_code;
                    this.form.ship_from_postal_code = this.pkg.ship_from.zip_code;
                    this.form.ship_to_country_code = this.pkg.ship_from.country_code;
                    this.form.ship_to_postal_code = this.pkg.ship_from.zip_code;

                    let pieces = [];
                    this.pkg.boxes.forEach(function (value) {
                        let piece = {
                            length: value.length.toString(),
                            width: value.width.toString(),
                            weight: value.weight.toString(),
                            height: value.height.toString(),
                        };
                        pieces.push(piece);
                    });

                    this.form.dimensions = pieces;
                    this.loading = false;
                    this.fetchRates();
                    this.show_results = true;

                    this.length = this.form.dimensions[0].length;
                    this.width = this.form.dimensions[0].width;
                    this.height = this.form.dimensions[0].height;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        dimension(type) {
            if (type == 'length') {
                if (this.form.dimensions[0].length) {
                    this.length = this.form.dimensions[0].length;
                } else {
                    this.length = "L";
                }
            }

            if (type == 'width') {
                if (this.form.dimensions[0].width) {
                    this.width = this.form.dimensions[0].width;
                } else {
                    this.width = "W";
                }
            }

            if (type == 'height') {
                if (this.form.dimensions[0].height) {
                    this.height = this.form.dimensions[0].height;
                } else {
                    this.height = "H";
                }
            }
        },
        addDimension() {
            this.form.dimensions.push({
                weight: "",
                length: "",
                width: "",
                height: "",
            });
        },
        removeDimension(index) {
            this.form.dimensions.splice(index, 1);
            this.fetchRates();
        },
        getRates() {
            const element = document.getElementById('rates-form');
            element.scrollIntoView({ behavior: 'smooth' });
        },
        shipDomesticFunc(option) {

            this.shipDomestic = option === 'us';

            if (option == 'us') {
                this.form.ship_from_country_code = "US";
                this.form.ship_to_country_code = "US";
            } else {
                this.form.ship_to_country_code = "";
            }
        },
    },
    setup() {
        onMounted(() => {
            AOS.init({
                duration: 1200,
                easing: 'ease-in-out',
                // once: true,
            });
        });
    }
};
</script>

<style>
.nav_item_active {
    background: linear-gradient(to left bottom, #FFD200, #CD7D00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.text-linear {
    background: linear-gradient(to left bottom, #FFD200, #CD7D00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}


.border-linear {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #FFD200, #CD7D00) border-box;
    border-radius: 50em;
    border: 3px solid transparent;
}

.border-b-linear {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(white, white) padding-box,
        linear-gradient(white, white) padding-box,
        linear-gradient(white, white) padding-box,
        linear-gradient(to right, #FFD200, #CD7D00) border-box;
    border: 0;
    border-bottom: 3px solid transparent;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: none;
    position: static;
}

.swiper-button-next,
.swiper-button-prev {
    position: static;
}

.swiper-button-prev:after {
    content: '';
}

.swiper-button-next:after {
    content: '';
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 10px; */
}

/* ----------- Section 2 Cards ------------ */
.section2_card {
    position: relative;
}

.section2_card::before {
    position: absolute;
    content: "";
    height: 80%;
    width: 95%;
    top: 60%;
    left: -12.5%;
    background: white;
    border-radius: 1rem;
    box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
    z-index: -1;

}

.section2_card::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    z-index: -2;
    top: 50%;
    left: -15%;
    border-radius: 1rem;
    background: linear-gradient(to right, #FFD200, #CD7D00);
}

@media screen and (max-width: 1746px) {
    .section2_card::before {
        left: -5.5%;
    }

    .section2_card::after {
        left: -8%;
    }
}

@media screen and (max-width:640px) {

    /* body{
            font-size: 14px;
        } */
    .section2_card::before {
        left: 2.2%;
        top: 33%;
    }

    .section2_card::after {
        left: 0.1%;
        top: 20%;
    }

    .section2_card {
        width: 100%;
    }
}


select.minimal {

    /* reset */

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

select.minimal {
    background-image:
        linear-gradient(45deg, transparent 50%, #676767 50%),
        linear-gradient(135deg, #676767 50%, transparent 50%) !important;
    background-position:
        calc(100% - 19.7px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em !important;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em !important;
    background-repeat: no-repeat !important;
}

select.minimal:focus {
    background-image:
        linear-gradient(45deg, #676767 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, #676767 50%) !important;
    background-position:
        calc(100% - 15.7px) 1em,
        calc(100% - 21px) 1em,
        calc(100% - 2.5em) 0.5em !important;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em !important;
    background-repeat: no-repeat !important;
}
</style>